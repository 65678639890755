import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
} from '@ionic/angular';
import { DataService } from 'src/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public language = 'de';

    constructor(
        private loadingCtrl: LoadingController,
        private dataService: DataService,
        private translate: TranslateService,
        public router: Router,
        private alertCtrl: AlertController,
        private cdr: ChangeDetectorRef,
        private meta: Meta,
        private title: Title
    ) {}

    ngOnInit() {
        if (environment.biocare) {
            this.title.setTitle('TrichoCloud - Flächenerfassung');
            this.setFavicon('assets/trichocloud-favicon.png');

        } else {
            this.title.setTitle('Webaro - Flächenerfassung');
            this.setFavicon('assets/images/favicon.png');
        }
        // this.translate.setDefaultLang('de');
        this.loadConfiguration();
        if (this.dataService.isIE()) {
            this.router.navigateByUrl('old-browser');
        }
    }

    setFavicon(url: string): void {
        const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = url;

        document.getElementsByTagName('head')[0].appendChild(link);
      }

    async loadConfiguration() {
        const loading = await this.loadingCtrl.create();
        await loading.present();

        try {
            const config: any = await this.dataService.loadConfiguration();
            if (config?.portal?.order_head_script) {
                try {
                    const script = document.createElement('script');
                    script.innerHTML =
                        config?.portal?.order_head_script?.replace(
                            /< *\/? *script[^>]*>/g,
                            ''
                        );
                    document.querySelector('head').appendChild(script);
                } catch (e) {
                    console.warn(
                        this.translate.instant('headScriptProblem'),
                        e
                    );
                }
            }
            this.setLanguage(config.portal?.locale?.split('_')[0]);
        } catch (e) {
            this.setLanguage();
        } finally {
            await loading.dismiss();
        }
    }

    setLanguage(language?: string) {
        let usedLanguage;
        const localLanguage = JSON.parse(
            localStorage.getItem('iframe-language')
        );
        if (localLanguage) {
            usedLanguage = localLanguage;
        } else if (language) {
            usedLanguage = language;
        } else {
            usedLanguage = 'de';
        }
        this.translate.use(usedLanguage);
        this.language = usedLanguage;
        this.cdr.detectChanges();
    }

    public async openLanguage(): Promise<any> {
        const actionSheetCtrl = await this.alertCtrl.create({
            header: this.translate.instant('Select Language'),
            mode: 'ios',
            translucent: true,
            buttons: [
                {
                    text: 'Deutsch',
                    handler: () => {
                        this.changeLanguage('de');
                    },
                },
                {
                    text: 'English',
                    handler: () => {
                        this.changeLanguage('en');
                    },
                },
                {
                    text: 'Français',
                    handler: () => {
                        this.changeLanguage('fr');
                    },
                },
            ],
        });

        await actionSheetCtrl.present();
    }

    async changeLanguage(selection: string) {
        localStorage.setItem('iframe-language', JSON.stringify(selection));
        this.language = selection;
        this.translate.use(selection);
    }
}
